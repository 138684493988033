import React from "react";

const ToAddressField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="toAddress">Město do ...</label>
      <input
        id="toAddress"
        name="Adresa do"
        type="text"
        className="p-2.5 mt-1 mb-4 rounded border border-gray-300"
        placeholder="Napište město nebo celou adresu"
        onChange={onChange}
      />
    </>
  );
};

export default ToAddressField;
