import React, { useState } from "react";
import StarRatingComponent from "./companiesList/StarRating";
import PriceCategory from "./companiesList/PriceCategory";
import WorkloadIndicator from "./companiesList/WorkloadIndicator";
import SazbaDisplay from "./companiesList/SazbaDisplay";
import VehicleRatesDisplay from "./companiesList/VehicleRatesDisplay";
import InsuranceInfo from "./companiesList/InsuranceInfo";
import CompanyServices from "./companiesList/CompanyService";
import MoreInfoButton from "./companiesList/MoreInfoButton";

function CompaniesList({
  companies,
  onCompanySelect,
  handleSearch,
  currentSearchTerm,
  city,
  scrollToRequestForm,
}) {
  const [selectAll, setSelectAll] = useState(false);

  const [selectedCompanyIds, setSelectedCompanyIds] = useState(new Set());
  const [expandedCompany, setExpandedCompany] = useState(null);

  const toggleCompanySelection = (company) => {
    setSelectedCompanyIds((prevSelected) => {
      const newSelected = new Set(prevSelected);
      const companyIdWithCity = `${company.Firma}-${company.Mesto}`;
      if (newSelected.has(companyIdWithCity)) {
        newSelected.delete(companyIdWithCity);
      } else {
        newSelected.add(companyIdWithCity);
      }
      onCompanySelect(
        Array.from(newSelected).map((id) => {
          const [name, city] = id.split("-");
          return { name, city };
        })
      );
      return newSelected;
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const allCompanyIds = new Set(
        companies.map((company) => `${company.Firma}-${company.Mesto}`)
      );
      setSelectedCompanyIds(allCompanyIds);
      onCompanySelect(
        companies.map((company) => ({
          name: company.Firma,
          city: company.Mesto,
        }))
      );
    } else {
      setSelectedCompanyIds(new Set());
      onCompanySelect([]);
    }
  };

  const handleMoreInfoClick = (company) => {
    setExpandedCompany(
      expandedCompany === company.Firma ? null : company.Firma
    );
  };

  const renderExpandedInfo = (company) => (
    <div className="expanded-info mt-4 flex justify-between p-10">
      <div className="more-info-mobile">
        <div className="flex flex-col">
          {/* Vehicle Information with Icons */}
          <div className="flex flex-col custom-vuz custom-sloupec-vozy">
            <VehicleRatesDisplay company={company} />
          </div>

          {/* Sazba Information */}
          <div className="flex flex-row items-center ">
            <div className="flex flex-col sazba-osob custom-sloupec-sazba ">
              <SazbaDisplay company={company} />
            </div>
          </div>
        </div>
      </div>

      {/* Insurance */}
      <div className="kryti-part mobile-kryti-part custom-sloupec-kryti">
        <p>Pojištění</p>
        <div className="flex flex-row font-normal">
          <InsuranceInfo
            isInsured={company.Pojisteni}
            coverage={company.Kryti}
          />
        </div>
      </div>
      <CompanyServices company={company} />
    </div>
  );

  return (
    <section className="pb-12 border-b border-gray-200 custom-section-companies">
      <div className="grid-container whole-companies-list">
        {/* Display the city here */}
        {city && (
          <div className="city-display">
            <p>
              Jednoduše poptejte vybrané firmy ve Vaší lokalitě{" "}
              <strong>{city}</strong>
            </p>
          </div>
        )}

        {/* Grid Container */}
        <div className="grid-container">
          {/* Headers */}
          <div className="mb-2 mobile-ui-names headings-correct-aligment">
            <div className="classa-na-firmu">
              <div id="firma">Firma:</div>
            </div>
            <div className="classa-na-dinfo">
              <div id="km">Cena za Km:</div>
              <div id="hs">Hodinová sazba:</div>
              <div
                id="sort-hodnoceni"
                className="sortable"
                onClick={() => handleSearch(currentSearchTerm, "Hodnoceni")}
              >
                Hodnocení: <span className="triangle"></span>
              </div>
              <div
                id="sort-cena"
                className="sortable"
                onClick={() => handleSearch(currentSearchTerm, "Cenova_kateg")}
              >
                Cena: <span className="triangle"></span>
              </div>
              <div id="pojisteni">Pojištění:</div>
              <div id="vytizeni">Vytížení:</div>
            </div>
          </div>

          {companies.map((company) => (
            <div
              key={company.Firma}
              className="company flex flex-col border  rounded mb-4"
              onClick={() => handleMoreInfoClick(company)}
            >
              <div className="flex items-center justify-between custom-wor-mobile">
                {/* Checkbox */}
                <div
                  className={`checkbox-custom ${
                    selectedCompanyIds.has(`${company.Firma}-${company.Mesto}`)
                      ? "checked"
                      : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCompanySelection(company);
                  }}
                ></div>
                <div className="custom-mobile-column">
                  {/* Company Name */}
                  <h2 className="font-bold custom-company custom-sloupec-firma">
                    {company.Firma}
                  </h2>

                  <div className="custom-mobile-row company-info-for-pc">
                    {/* Vehicle Rates */}
                    <div
                      className="flex flex-col custom-vuz custom-sloupec-vozy"
                      id="custom-show-vuz"
                    >
                      <VehicleRatesDisplay company={company} />
                    </div>

                    <div
                      className="flex justify-start items-center custom-sloupec-sazba"
                      id="custom-show-sazba"
                    >
                      <div className="flex flex-row items-center sazba-osob">
                        <SazbaDisplay company={company} />
                      </div>
                    </div>

                    <StarRatingComponent company={company} />

                    {/* Price Category */}
                    <p className="mr-3 custom-sloupec-cena">
                      <PriceCategory category={company.Cenova_kateg} />
                    </p>

                    {/* Insurance */}
                    <div className="kryti-part custom-kryti-part custom-sloupec-kryti">
                      <InsuranceInfo
                        isInsured={company.Pojisteni}
                        coverage={company.Kryti}
                      />
                    </div>

                    <WorkloadIndicator workload={company.Vytizeni} />
                  </div>
                </div>

                {/* More Info Button */}
                <MoreInfoButton
                  company={company}
                  expandedCompany={expandedCompany}
                />
              </div>
              {expandedCompany === company.Firma && renderExpandedInfo(company)}
            </div>
          ))}
        </div>
        {/* "Select All" Checkbox */}
        <div className="sirka-end-company">
          <div className="flex flex-row space-between end-company">
            <div className="flex flex-row font-bold justify-start whole-companies-list zaskrtnout-vse">
              <div
                onClick={handleSelectAllChange}
                className={`checkbox-custom ${selectAll ? "checked" : ""}`}
              ></div>
              <p>Označit všechny firmy</p>
            </div>
            <button
              onClick={() => scrollToRequestForm()}
              className="flex items-center justify-center p-2 font-bold overflow-hidden custom-submit-button custom-end-button"
            >
              Poptat vybrané
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CompaniesList;
