import React, { useState } from "react";
import vanliLogo from "../images/vanli-cz.svg";
import searchIcon from "../images/lupa.svg";
import locationIcon from "../images/pin.svg";

function SearchSection({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(searchTerm);
  };

  return (
    <section className="search-section relative flex">
      <div className="flex flex-col search-main">
        {/* Information Box */}
        <div className=" absolute flex flex-row items-start custom-information-padding ">
          <div className="flex items-center">
            <img src={"/icon/tel.svg"} alt="Phone" className="w-6 h-4 mr-2" />
            <a href="tel: 608 061 437" className="phone-number">
              608 061 437
            </a>
          </div>
          <div>
            <span className="time-number">Po – Pá: 8:00 – 19:00</span>
          </div>
        </div>
        {/* Logo and Search Box Container */}
        <div className="flex flex-col justify-center items-start search-padding-top">
          <img src={vanliLogo} alt="Vanli.cz" className="mb-4" />
          <p className="mb-24 popisek">
            Všechny stěhovací firmy na jednom místě
          </p>
          <p className="mb-2 font-semibold odkud">Odkud stěhujete</p>
          <form onSubmit={handleSubmit} className="w-full flex items-center">
            <div className="relative flex items-center bg-white border overflow-hidden citySearch">
              <img src={locationIcon} alt="Location" className="ikona-poloha" />
              <input
                type="text"
                id="citySearch"
                placeholder="Město"
                className="p-4  w-full focus:outline-none "
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>
            <button
              type="submit"
              className="flex items-center justify-center p-2  overflow-hidden custom-submit-button"
            >
              <img
                src={searchIcon}
                alt="Search"
                className="w-5 h-5 mr-2 ikona-search"
              />
              Hledat {/* "Search" */}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}

export default SearchSection;
