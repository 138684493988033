import React from "react";

const NoteField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="note">Poznámka</label>
      <textarea
        id="note"
        name="Poznámka"
        className="input1"
        onChange={onChange}
      />
    </>
  );
};

export default NoteField;
