import React from "react";

const DateField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="date">Předpokládané datum</label>
      <input
        id="date"
        name="Předpokládané datum"
        type="date"
        className="input1"
        onChange={onChange}
      />
    </>
  );
};

export default DateField;
