import React from "react";

const InsuranceInfo = ({ isInsured, coverage }) => {
  return isInsured === "TRUE" ? (
    <>
      <img
        src="./icon/check-mark.svg"
        className="mx-2 text-green-600"
        alt="Check Mark"
      />
      <p className="mx-2">{coverage}</p>
    </>
  ) : (
    <img
      src="./icon/cross-mark.svg"
      className="mx-2 text-red-600"
      alt="Cross Mark"
    />
  );
};

export default InsuranceInfo;
