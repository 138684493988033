import React from "react";

const MovingItemsField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="movingItems">Položka služby (povinné)</label>
      <textarea
        id="movingItems"
        name="Položky a služby"
        className="input1"
        required
        onChange={onChange}
        placeholder="Položky nebo velikost bytu, který chcete vystěhovat."
      />
    </>
  );
};

export default MovingItemsField;
