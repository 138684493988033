import React from "react";

const PhoneField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="phone" className="mb-2">
        Telefon
      </label>
      <input
        id="phone"
        name="Telefon"
        type="tel"
        className="p-2.5 mb-4 rounded border border-gray-300"
        onChange={onChange}
      />
    </>
  );
};

export default PhoneField;
