import React from "react";

const MoreInfoButton = ({ company, expandedCompany }) => {
  const isExpanded = expandedCompany === company.Firma;

  return (
    <div
      className="text-black flex flex-col items-center justify-center cursor-pointer"
      style={{
        transform: isExpanded ? "rotate(180deg)" : "none",
        transition: "transform 0.4s",
        transformOrigin: "center",
      }}
    >
      <img
        src="/icon/scroll.svg"
        alt="More Info"
        className="icon-class-sipka"
      />
    </div>
  );
};

export default MoreInfoButton;
