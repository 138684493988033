import React from "react";

const EmailField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="email" className="mb-2">
        E-mail (povinné)
      </label>
      <input
        id="email"
        name="email"
        type="email"
        required
        className="p-2.5 mb-4 rounded border border-gray-300"
        onChange={onChange}
      />
    </>
  );
};

export default EmailField;
