import React from "react";

const IconsDisplay = ({ count }) => {
  return (
    <div className="flex items-center">
      {Array.from({ length: count }, (_, i) => (
        <img
          key={i}
          src="/icon/person.svg"
          alt="Person"
          className="icon-class-person"
        />
      ))}
    </div>
  );
};

export default IconsDisplay;
