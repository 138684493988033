import React from "react";

const PriceCategory = ({ category }) => {
  const totalCategories = 4;

  return (
    <div>
      {Array.from({ length: totalCategories }, (_, i) => (
        <span
          key={i}
          className={i < category ? "text-current" : "text-gray-300"}
        >
          $
        </span>
      ))}
    </div>
  );
};

export default PriceCategory;
