import React from "react";

const FormInformation = () => {
  return (
    <>
      <h3 id="nadpis-custom-formular">
        Svoji cenu <br></br>znáte do 24h
        <img
          src="/icon/info-ico.png"
          alt="Information Icon"
          id="ikona-custom-formular"
        />
      </h3>

      <p id="custom-popis1-formular">
        Vybrané firmy za vás poptáme se zadanými údaji. Veškeré firmy jsou
        součástí našeho partnerského programu a získáte od nich co nejlepší
        možnou cenu.
      </p>
      <p id="custom-popis2-formular">
        Poptávka je zcela nezávazná a slouží pouze k porovnání cen v moři
        stěhovacích firem.
      </p>
    </>
  );
};

export default FormInformation;
