import React from "react";

const NameField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="name" className="mb-2">
        Jméno a příjmení
      </label>
      <input
        id="name"
        name="Jméno"
        type="text"
        className="p-2.5 mb-4 rounded border border-gray-300"
        onChange={onChange}
      />
    </>
  );
};

export default NameField;
