import React from "react";

const RequestedCompaniesField = ({ value, onChange }) => {
  return (
    <>
      <label htmlFor="Poptavane_firmy">Poptávané firmy</label>
      <input
        value={value}
        id="Poptavane_firmy"
        name="Poptávané firmy"
        type="text"
        className="input1"
        readOnly
        onChange={onChange}
      />
    </>
  );
};

export default RequestedCompaniesField;
