import React from "react";

const FromAddressField = ({ onChange }) => {
  return (
    <>
      <label htmlFor="fromAddress">Město z ...</label>
      <input
        id="fromAddress"
        name="Adresa z"
        type="text"
        className="p-2.5 mt-1 mb-4 rounded border border-gray-300"
        placeholder="Napište město nebo celou adresu"
        onChange={onChange}
      />
    </>
  );
};

export default FromAddressField;
