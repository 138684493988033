import React, { useEffect, useState } from "react";
import sendIcon from "../images/send.svg";
import RequestedCompaniesField from "./requestFormSectionComponents/RequestedCompaniesField";
import DateField from "./requestFormSectionComponents/DateField";
import FromAddressField from "./requestFormSectionComponents/FromAddressField";
import ToAddressField from "./requestFormSectionComponents/ToAddressField";
import MovingItemsField from "./requestFormSectionComponents/MovingItemsField";
import NameField from "./requestFormSectionComponents/NameField";
import EmailField from "./requestFormSectionComponents/EmailField";
import PhoneField from "./requestFormSectionComponents/PhoneField";
import NoteField from "./requestFormSectionComponents/NoteField";
import TermsCheckbox from "./requestFormSectionComponents/TermsCheckbox";
import FormInformation from "./requestFormSectionComponents/FormInformation";

const RequestFormSection = ({ selectedCompanies }) => {
  const [formData, setFormData] = useState({
    Poptavane_firmy: selectedCompanies.map((c) => c.Firma).join(", "),
  });
  const [isChecked, setIsChecked] = useState(false);
  const [, setIsSubmitting] = useState(false);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      Poptavane_firmy: selectedCompanies.map((c) => c.Firma).join(", "),
    }));
  }, [selectedCompanies]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setIsChecked(checked);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!isChecked) {
      alert("Please agree to the terms and conditions before submitting.");
      return; // Prevent form submission if terms are not agreed to
    }

    setIsSubmitting(true);

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value);
    });

    try {
      const response = await fetch("https://vanli.cz/postForm.php", {
        method: "POST",
        body: formDataToSend,
      });

      if (response.ok) {
        console.log("Form submitted successfully");
        window.location.href = "/dekujeme/index.php";
      } else {
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="form-seaction-whole pt-12">
      <form onSubmit={handleFormSubmit} id="form-custom">
        <h2 className="form-section-h2">Poptávkový formulář</h2>

        {/* Date Field */}
        <RequestedCompaniesField
          value={formData.Poptavane_firmy}
          onChange={handleChange}
        />

        {/* Date Field */}
        <DateField onChange={handleChange} />

        <div className="flex flex-row justify-between ">
          <div className="flex flex-col w-1/2">
            {/* From Address Field */}
            <FromAddressField onChange={handleChange} />
          </div>

          <div className="flex flex-col w-1/2">
            {/* To Address Field */}
            <ToAddressField onChange={handleChange} />
          </div>
        </div>

        {/* Moving Items Field */}
        <MovingItemsField onChange={handleChange} />

        <div className="custom-form-zelena">
          {/* Contact Information */}
          <div className="flex flex-col">
            <NameField onChange={handleChange} />
          </div>

          <div className="flex flex-row justify-between gap-2">
            <div className="flex flex-col custom-mobile-fix-sirky">
              {/* Email Field */}
              <EmailField onChange={handleChange} />
            </div>

            <div className="flex flex-col custom-mobile-fix-sirky">
              {/* Phone Field */}
              <PhoneField onChange={handleChange} />
            </div>
          </div>
          <div className="flex flex-col mb-4">
            {/* Note Field */}
            <NoteField onChange={handleChange} />
          </div>

          {/* Terms and Conditions Checkbox */}
          <div className="terms-conditions-custom">
            <TermsCheckbox
              isChecked={isChecked}
              handleCheckboxChange={handleCheckboxChange}
            />
          </div>

          {/* Submit Button */}
          <button type="submit" className="custom-submit-button2">
            <img src={sendIcon} alt="Send" />
            Odeslat poptávku
          </button>
        </div>
      </form>
      {/* Right Section for text */}
      <div className="custom-formular-info">
        <FormInformation />
      </div>
    </div>
  );
};

export default RequestFormSection;
