import React from "react";

// Assuming createStarRating is kept outside as a utility function
const createStarRating = (average) => {
  const fullStars = Math.floor(average);
  const hasHalfStar = average % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(
      <img
        key={`full_${i}`}
        src="/icon/full-star-ico.png"
        alt="Full Star"
        className="star-icon full-star"
      />
    );
  }

  if (hasHalfStar) {
    stars.push(
      <img
        key="half"
        src="/icon/half-star-ico.png"
        alt="Half Star"
        className="star-icon half-star"
      />
    );
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <img
        key={`empty_${i}`}
        src="/icon/empty-star-ico.png"
        alt="Empty Star"
        className="star-icon empty-star"
      />
    );
  }

  return <div className="flex">{stars}</div>;
};

// Component that uses createStarRating
const StarRatingComponent = ({ company }) => {
  return (
    <div className="hodnoceni custom-sloupec-hodnoceni">
      {/* Star Rating */}
      {createStarRating(parseFloat(company.Hod_prumer))}
      {/* Ratings Count */}
      <p className="ml-2 mr-3">({company.Hod_pocet})</p>
    </div>
  );
};

export default StarRatingComponent;
