import React from "react";

const workloadStyles = {
  1: { backgroundColor: "rgb(47, 222, 140)", text: "Nízké" },
  2: { backgroundColor: "rgb(226, 207, 35)", text: "Střední" },
  3: { backgroundColor: "rgb(241, 90, 36)", text: "Vysoké" },
};

const WorkloadIndicator = ({ workload }) => {
  const workloadNumber = parseInt(workload, 10);
  const workloadInfo = workloadStyles[workloadNumber];

  if (!workloadInfo) return null;

  return (
    <div className="flex flex-col items-center justify-center mr-3 custom-sloupec-vytizeni">
      <div
        style={{ backgroundColor: workloadInfo.backgroundColor }}
        className="h-3 w-3 rounded-full"
      ></div>
      <span className="text-xs mt-1">{workloadInfo.text}</span>
    </div>
  );
};

export default WorkloadIndicator;
