import React, { useState, useEffect, useRef } from "react";
import Papa from "papaparse";
import SearchSection from "./components/SearchSection"; // Make sure the path is correct
import CompaniesList from "./components/CompaniesList"; // Make sure the path is correct
import RequestFormSection from "./components/RequestFormSection"; // Make sure the path is correct
import "./RequestFormSection.css";

function App() {
  const [companiesData, setCompaniesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]); // Define the state for filtered data
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const resultsSectionRef = useRef(null);
  const [searchTermForDisplay, setSearchTermForDisplay] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [showRequestForm, setShowRequestForm] = useState(false);

  useEffect(() => {
    Papa.parse("/tabulka.csv", {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        console.log("CSV Data Loaded:", results.data); // Log the loaded data
        setCompaniesData(results.data);
      },
      error: (error) =>
        console.error("Error loading or parsing CSV data:", error),
    });
    console.log("Updated Selected Companies:", selectedCompanies);

    if (showRequestForm && requestFormSectionRef.current) {
      setTimeout(() => {
        requestFormSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100); // Adjust the timeout as needed
    }
  }, [selectedCompanies, showRequestForm]);

  const handleSearch = (
    newSearchTerm = searchTerm,
    sortBy = "Hod_relevance"
  ) => {
    if (newSearchTerm !== searchTerm) {
      setSearchTerm(newSearchTerm);
    }

    const currentDirection = sortDirection[sortBy];
    const newDirection = currentDirection === "asc" ? "desc" : "asc";
    setSortDirection({ ...sortDirection, [sortBy]: newDirection });

    setSearchTermForDisplay(newSearchTerm.trim());

    const removeDiacritics = (str) =>
      str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    let filtered = companiesData.filter(
      (company) =>
        removeDiacritics(company.Mesto.toLowerCase()) ===
        removeDiacritics(newSearchTerm.toLowerCase().trim())
    );

    if (filtered.length === 0) {
      filtered = companiesData.filter((company) => company.Mesto === "CZ");
      if (filtered.length > 0) {
        setSearchCity("CZ");
      } else {
        setSearchCity("");
      }
    } else {
      setSearchCity(filtered[0].Mesto);
    }

    const sortedFiltered = sortData(filtered, sortBy, newDirection);
    setFilteredData(sortedFiltered);
    setHasSearched(true);

    if (resultsSectionRef.current) {
      setTimeout(() => {
        resultsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const [sortDirection, setSortDirection] = useState({
    Cenova_kateg: "asc", // 'asc' for ascending, 'desc' for descending
    Hod_prumer: "asc",
    Hod_relevance: "asc",
  });

  // Helper function to sort the data based on a given criterion.
  function sortData(data, sortBy, direction) {
    return data.sort((a, b) => {
      let valueA, valueB;
      switch (sortBy) {
        case "Cenova_kateg":
          valueA = parseInt(a.Cenova_kateg, 10);
          valueB = parseInt(b.Cenova_kateg, 10);
          break;
        case "Hod_prumer":
          valueA = parseFloat(a.Hod_prumer);
          valueB = parseFloat(b.Hod_prumer);
          break;
        case "Hodnoceni": // Assuming this maps to 'Hod_relevance'
          valueA = parseFloat(a.Hod_relevance);
          valueB = parseFloat(b.Hod_relevance);
          break;
        default:
          valueA = parseFloat(a.Hod_relevance);
          valueB = parseFloat(b.Hod_relevance);
      }
      // Apply the sort direction
      return direction === "asc" ? valueA - valueB : valueB - valueA;
    });
  }

  const handleCompanySelection = (selectedCompaniesInfo) => {
    setSelectedCompanies(
      companiesData.filter((company) =>
        selectedCompaniesInfo.some(
          (info) => company.Firma === info.name && company.Mesto === info.city
        )
      )
    );
    console.log("Updated Selected Companies:", selectedCompanies);
  };

  const handleMoreInfo = (company) => {
    // Logic to handle more info click
    console.log("More info for:", company);
  };

  const requestFormSectionRef = useRef(null);

  const scrollToRequestForm = () => {
    // Only set the visibility state, removing the scroll logic
    setShowRequestForm(true);
  };

  return (
    <div className="App">
      <SearchSection onSearch={handleSearch} setSearchTerm={setSearchTerm} />
      <div ref={resultsSectionRef}>
        {hasSearched && (
          <>
            <CompaniesList
              companies={filteredData}
              onCompanySelect={handleCompanySelection}
              onMoreInfo={handleMoreInfo}
              handleSearch={handleSearch}
              currentSearchTerm={searchTerm}
              searchTermForDisplay={searchTermForDisplay}
              city={searchCity}
              // Pass scrollToRequestForm function as a prop
              scrollToRequestForm={scrollToRequestForm}
            />
            {/* Wrap RequestFormSection with a div and attach the ref */}
            {showRequestForm && (
              <div ref={requestFormSectionRef}>
                <RequestFormSection selectedCompanies={selectedCompanies} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default App;
