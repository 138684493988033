import React from "react";

const CompanyServices = ({ company }) => {
  return (
    <>
      <div className="left-info">
        <ServiceItem label="Demontáž" value={company.Dem} />
        <ServiceItem label="Zpětná montáž" value={company.Mon} />
        <ServiceItem label="Obalení nábytku" value={company.Obal} />
        <ServiceItem label="Břemena nad 200kg" value={company.Brem} />
      </div>
      <div className="right-info">
        <ServiceItem label="Mezinárodní stěhování" value={company.Mezi} />
        <p>
          <span className="label">Zápůjčka krabic:</span>
          <span className="status">{company.Zapuj} Kč/ks</span>
        </p>
        <p>
          <span className="label">Objednání:</span>
          <span className="status">{company.Objed}</span>
        </p>
        <p>
          <span className="label">Min. objednávka:</span>
          <span className="status">{company.MinObjed}</span>
        </p>
      </div>
      <div className="text-info">
        <p className="additional-info-title">Další info:</p>
        <p className="additional-info">{company.Info}</p>
      </div>
    </>
  );
};

const ServiceItem = ({ label, value }) => (
  <p>
    <span className="label">{label}:</span>
    <span className={`icon ${value === "TRUE" ? "checkmark" : "cross"}`}></span>
    <span className="status">{value === "TRUE" ? "Ano" : "Ne"}</span>
  </p>
);

export default CompanyServices;
