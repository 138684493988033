import React from "react";
import VehicleRate from "./VehicleRate";

const VehicleRatesDisplay = ({ company }) => {
  return (
    <div>
      <VehicleRate rate={company.Vuz_1} imageSrc="/icon/car-1.svg" />
      <VehicleRate rate={company.Vuz_2} imageSrc="/icon/car-2.svg" />
      <VehicleRate rate={company.Vuz_3} imageSrc="/icon/car-3.svg" />
      <VehicleRate rate={company.Vuz_4} imageSrc="/icon/car-4.svg" />
    </div>
  );
};

export default VehicleRatesDisplay;
