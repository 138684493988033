import React from "react";

const TermsCheckbox = ({ isChecked, handleCheckboxChange }) => {
  return (
    <>
      <input
        id="termsAccepted"
        type="checkbox"
        name="Podmínky"
        checked={isChecked}
        onChange={handleCheckboxChange}
        required
      />
      <label htmlFor="termsAccepted" id="souhlas-podminky">
        Souhlasím se{" "}
        <a href="/soubory/osob_udaje.php" id="souhlas-underline-podminky">
          zpracováním os. údajů
        </a>{" "}
        pro účely zpracování poptávky.
      </label>
    </>
  );
};

export default TermsCheckbox;
