import React from "react";
import IconsDisplay from "./IconDisplay";

const SazbaDisplay = ({ company }) => {
  return (
    <div className="flex flex-col">
      {company.Sazba_1 && (
        <div className="flex items-center justify-between">
          <IconsDisplay count={1} />
          <span className="ml-2 font-bold">{company.Sazba_1},- /h</span>
        </div>
      )}
      {company.Sazba_2 && (
        <div className="flex items-center justify-between">
          <IconsDisplay count={2} />
          <span className="ml-2 font-bold">{company.Sazba_2},- /h</span>
        </div>
      )}
      {company.Sazba_3 && (
        <div className="flex items-center justify-between">
          <IconsDisplay count={3} />
          <span className="ml-2 font-bold">{company.Sazba_3},- /h</span>
        </div>
      )}
      {company.Sazba_4 && (
        <div className="flex items-center justify-between">
          <IconsDisplay count={4} />
          <span className="ml-2 font-bold">{company.Sazba_4},- /h</span>
        </div>
      )}
    </div>
  );
};

export default SazbaDisplay;
