import React from "react";

const VehicleRate = ({ rate, imageSrc }) => {
  if (!rate) return null;

  return (
    <div className="flex items-center justify-between auto-ikony">
      <img src={imageSrc} alt="Vehicle" className="mr-2" />
      <div>{rate} Kč/km</div>
    </div>
  );
};

export default VehicleRate;
